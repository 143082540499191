<template>
  <b-form-group
    label="Alt İçerik"
    label-for="subcontent_toolbar"
  >

    <quill-editor
      v-model="dataItem.subcontent"
      :options="editorOption"
      class="bg-white text-black-50"
    >
      <div
        id="subcontent_toolbar"
        slot="toolbar"
      >
        <editor-toolbar />
      </div>
    </quill-editor>

  </b-form-group>
</template>

<script>
import { localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import EditorToolbar from '@/layouts/components/common/EditorToolbar.vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'SubContent',
  components: {
    BFormGroup,
    quillEditor,

    EditorToolbar,
  },
  data() {
    return {
      required,
      editorOption: {
        modules: {
          toolbar: '#subcontent_toolbar',
        },
        theme: 'snow',
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['hrPositions/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
