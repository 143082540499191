var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Başlık","label-for":"title"}},[_c('validation-provider',{attrs:{"name":"Başlık","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","placeholder":"Başlık"},model:{value:(_vm.itemData.title),callback:function ($$v) {_vm.$set(_vm.itemData, "title", $$v)},expression:"itemData.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Alt Başlık","label-for":"subtitle"}},[_c('validation-provider',{attrs:{"name":"Alt Başlık","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"subtitle","placeholder":"Alt Başlık"},model:{value:(_vm.itemData.subtitle),callback:function ($$v) {_vm.$set(_vm.itemData, "subtitle", $$v)},expression:"itemData.subtitle"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Departman","label-for":"department"}},[_c('validation-provider',{attrs:{"name":"Departman","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"department","placeholder":"Departman"},model:{value:(_vm.itemData.department),callback:function ($$v) {_vm.$set(_vm.itemData, "department", $$v)},expression:"itemData.department"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Sıra No","label-for":"ordering"}},[_c('validation-provider',{attrs:{"name":"Sıra No","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ordering","placeholder":"Sıra No"},model:{value:(_vm.itemData.ordering),callback:function ($$v) {_vm.$set(_vm.itemData, "ordering", $$v)},expression:"itemData.ordering"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('Content')],1),_c('b-col',{attrs:{"cols":"12"}},[_c('sub-content')],1)],1)],1),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.itemData.submitStatus},on:{"click":_vm.submitForm}},[_vm._v(" Kaydet ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }