<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Başlık"
            label-for="title"
          >
            <validation-provider
              #default="{ errors }"
              name="Başlık"
              rules="required"
            >
              <b-form-input
                id="title"
                v-model="itemData.title"
                placeholder="Başlık"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Alt Başlık"
            label-for="subtitle"
          >
            <validation-provider
              #default="{ errors }"
              name="Alt Başlık"
              rules="required"
            >
              <b-form-input
                id="subtitle"
                v-model="itemData.subtitle"
                placeholder="Alt Başlık"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Departman"
            label-for="department"
          >
            <validation-provider
              #default="{ errors }"
              name="Departman"
              rules="required"
            >
              <b-form-input
                id="department"
                v-model="itemData.department"
                placeholder="Departman"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Sıra No"
            label-for="ordering"
          >
            <validation-provider
              #default="{ errors }"
              name="Sıra No"
              rules="required"
            >
              <b-form-input
                id="ordering"
                v-model="itemData.ordering"
                placeholder="Sıra No"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <Content />
        </b-col>
        <b-col
          cols="12"
        >
          <sub-content />
        </b-col>

      </b-row>
    </b-card>
    <b-button
      variant="primary"
      :disabled="itemData.submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BCard, BCol, BFormGroup, BFormInput, BRow, BButton,
} from 'bootstrap-vue'

import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import SubContent from '@/views/Admin/HrPositions/elements/Subcontent.vue'
import Content from '@/views/Admin/HrPositions/elements/Content.vue'

export default {
  name: 'ItemForm',
  components: {
    ValidationProvider,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    SubContent,
    Content,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      imageUrl: this.$store.state.app.baseURL,
      required,
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['hrPositions/dataItem']
    },
  },
}
</script>
